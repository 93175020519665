/* Customize your main colors in :root variables */
:root {
  --main-background-color: #000000;
  --sec-background-color: #000000;
  --card-background-color: #000000;
  --countdown-background-color: #ffd600;
  --countdown-text-color: #000000;
  --main-text-color: #F7F6F4;
  --sec-text-color: #868686;
  --title-text-color: #ffd600;

  /* Custom Settings */
  --nav-background-color: #000000;
  --mintbox-background-color: #000000;

  --progress-fill-color: #ffd600;
  --progress-empty-color: #3a3726;
  --mintinfo-background-color: #201f15;

  --buttons-background-color: #ffd600;
  --buttons-text-color: #000000;
  --buttons-border-radius: 10px;
  --number-border-radius: 5px;
  --plusbtn-border-radius: 50%;

  --font-family-primary: 'Inter', sans-serif;
  --font-family-secondary: 'Montserrat', sans-serif;

}

html {
  scroll-behavior: smooth;
}

body {
  background-color:var(--main-background-color);
  /* background: url("img/background1.png"); */
  /* background-repeat: no-repeat; */
  /* background-size: contain; */

  font-family: var(--font-family-primary);
  font-size: 1.1em;
}

h2,
p {
  color: var(--main-text-color);
}

h3 {
  font-weight: normal;
}

p {
  color: var(--sec-text-color);
  line-height: 2.0rem;
  font-weight: 400;
}

a {
  color: var(--main-text-color);
  text-decoration: none;
  transition: color 0.3s;
  padding-left: 5px;
}

a:hover,
a:active {
  color: rgb(219, 219, 219);
}

@keyframes scrollGrid {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 7200px 0;
  }
}
